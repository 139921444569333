import React, { useState, useEffect } from 'react';
import './App.css';
import { Offline, Online } from "react-detect-offline";

import OnlineView from "./Online";
import OfflineView from "./Offline";

function App() {


  const isMobile = () => {

    if ("ontouchstart" in document.documentElement) {
      return true
    } else {
      return false
    }

  }

  return (
    <div className="App">

      {isMobile() ? (
        <>
          <Online>
            <OnlineView />
          </Online>

          <Offline>
            <OfflineView />
          </Offline>
        </>

      ) : (
        <div className="desktopWrapper">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         width="30"
         height="30"
         viewBox="0 0 448 512"
       >
         <path fill="#fff" d="M104 104c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H88c-8.84 0-16-7.2-16-16v-16c0-8.8 7.16-16 16-16h16zm40-72c26.5 0 48 21.49 48 48v96c0 26.5-21.5 48-48 48H48c-26.51 0-48-21.5-48-48V80c0-26.51 21.49-48 48-48h96zm0 32H48c-8.84 0-16 7.16-16 16v96c0 8.8 7.16 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.84-7.2-16-16-16zM72 376c0-8.8 7.16-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H88c-8.84 0-16-7.2-16-16v-16zm72-88c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.51 0-48-21.5-48-48v-96c0-26.5 21.49-48 48-48h96zm0 32H48c-8.84 0-16 7.2-16 16v96c0 8.8 7.16 16 16 16h96c8.8 0 16-7.2 16-16v-96c0-8.8-7.2-16-16-16zm216-216c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16zM256 80c0-26.51 21.5-48 48-48h96c26.5 0 48 21.49 48 48v96c0 26.5-21.5 48-48 48h-96c-26.5 0-48-21.5-48-48V80zm32 0v96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.84-7.2-16-16-16h-96c-8.8 0-16 7.16-16 16zm-32 224c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16v68h64v-68c0-8.8 7.2-16 16-16s16 7.2 16 16v84c0 8.8-7.2 16-16 16h-96c-8.8 0-16-7.2-16-16v-68h-32v152c0 8.8-7.2 16-16 16s-16-7.2-16-16V304zm64 144c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16zm112-16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16z"></path>
       </svg>
       <div>Please load this url on your phone.</div>
     </div>

      )}

    </div>
  );
}

export default App;
