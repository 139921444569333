import React, { useState, useEffect } from 'react';
import './App.css';
import ls from "local-storage";
import { useMediaQuery } from 'react-responsive'

function App() {


  const [isPending, setIsPending] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [version, setVersion] = useState("v0.22");
  const [theGuid, setTheGuid] = useState(null);
  const [imageData, setimageData] = useState(null);
  const [vcard, setVcard] = useState(null);
  const [vcardImage, setVcardImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [debugInfo, setDebugInfo] = useState(null);

  const [isPWA, setIsPWA] = useState(false);



  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const getVDocForGUID = async (guid) => {

    setDebugInfo(true);

    //Test: 7w7ewgjg8j
    //Dev: tsnqw7khf3
    //Prod: 8xjxwn6va4
    
    let url = `https://8xjxwn6va4.execute-api.eu-west-2.amazonaws.com/prod/${guid}`;
    // let url = `https://tsnqw7khf3.execute-api.eu-west-2.amazonaws.com/dev/${guid}`;
    
    if (window.location.href.includes("https://test.vdocs.online/")){
      url = `https://7w7ewgjg8j.execute-api.eu-west-2.amazonaws.com/test/${guid}`;
    }

    //this changes depending on env var...
    const response = await fetch(url);

    const data = await response.json();

    // setDebugInfo(version + " Image url recieved: " + guid);

    if (data.result && data.result.status) {

      if ((data.result.status.toLowerCase() === "disabled" || data.result.status.toLowerCase() === "rejected")) {
        setIsDisabled(true);
        ls.set(guid, "disabled");
        setDebugInfo(false);

      } else if (data.result.status.toLowerCase() === "pending") {

        setIsPending(true);
        ls.set(guid, "pending");
        setDebugInfo(false);
      } else {
        setIsPending(null);
        setIsDisabled(null);
        setIsDeleted(null);
        setVcard(data);
        setVcardImage(data.result.document_image_hd);
      }

    } else {
      setIsDeleted(true);
      ls.set(guid, "deleted");
      setDebugInfo(false);
    }

    try {
      console.log(data.result.document_image_hd);
    } catch (error) {
      console.log(error);
    }
  };


  const getBase64Image = (url) => {

    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");

      ls.set(theGuid, dataURL);

      setDebugInfo(false);


    }
    img.src = url
  }

  const handleImageLoaded = (e) => {

    getBase64Image(vcardImage);
    setImageLoaded(true);

  }

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });




  useEffect(() => {

    setDebugInfo(false);

    let guid = params.guid;
    setTheGuid(guid);

    if (ls.get(guid)) {

      if (ls.get(guid) === "disabled") {
        setIsDisabled(true);
        setDebugInfo(false);
      } else if (ls.get(guid) === "pending") {
        setIsPending(true);
        setDebugInfo(false);
      } else if (ls.get(guid) === "deleted") {
        setIsDeleted(true);
        setDebugInfo(false);
      } else {
        setimageData(ls.get(guid));
        setImageLoaded(true);
        setDebugInfo(false);
      }

    } else {
      //Need to go online at least once.
    }


    if (navigator.standalone || window.matchMedia("(display-mode: standalone)").matches) {
      setIsPWA(true)
    } else {
      setIsPWA(false)
    }


    getVDocForGUID(guid);

  }, []);




  return (
    <>
     
      {(imageData) && <img style={{ opacity: imageLoaded ? 1 : 1 }} src={imageData} className="vcard-hd-image" />}
     
      {(vcardImage) && <img crossOrigin='annonymous' id="bannerImg" style={{ opacity: imageLoaded ? 1 : 0 }} onLoad={handleImageLoaded} src={vcardImage} className="vcard-hd-image" />}

      
      {!isPWA && (
        <div className="vcard-hd-cover"></div>
      )}


      {(!isPortrait) && (

        <div className="desktopWrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 448 512"
          >
            <path fill="#fff" d="M104 104c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H88c-8.84 0-16-7.2-16-16v-16c0-8.8 7.16-16 16-16h16zm40-72c26.5 0 48 21.49 48 48v96c0 26.5-21.5 48-48 48H48c-26.51 0-48-21.5-48-48V80c0-26.51 21.49-48 48-48h96zm0 32H48c-8.84 0-16 7.16-16 16v96c0 8.8 7.16 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.84-7.2-16-16-16zM72 376c0-8.8 7.16-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H88c-8.84 0-16-7.2-16-16v-16zm72-88c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H48c-26.51 0-48-21.5-48-48v-96c0-26.5 21.49-48 48-48h96zm0 32H48c-8.84 0-16 7.2-16 16v96c0 8.8 7.16 16 16 16h96c8.8 0 16-7.2 16-16v-96c0-8.8-7.2-16-16-16zm216-216c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16zM256 80c0-26.51 21.5-48 48-48h96c26.5 0 48 21.49 48 48v96c0 26.5-21.5 48-48 48h-96c-26.5 0-48-21.5-48-48V80zm32 0v96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V80c0-8.84-7.2-16-16-16h-96c-8.8 0-16 7.16-16 16zm-32 224c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16v68h64v-68c0-8.8 7.2-16 16-16s16 7.2 16 16v84c0 8.8-7.2 16-16 16h-96c-8.8 0-16-7.2-16-16v-68h-32v152c0 8.8-7.2 16-16 16s-16-7.2-16-16V304zm64 144c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16zm112-16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16h-16c-8.8 0-16-7.2-16-16v-16c0-8.8 7.2-16 16-16h16z"></path>
          </svg>
          <div>Please turn your screen to portrait.</div>
        </div>
      )}


      {(isPending) && (

      <div className="desktopWrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 512 512"
        >
          <path fill="#fff" d="M0 256C0 114.6 114.6 0 256 0s256 114.6 256 256-114.6 256-256 256S0 397.4 0 256zm96-48c-8.84 0-16 7.2-16 16v64c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16H96z"></path>
        </svg>
        <div>Pending approval.</div>
      </div>
      )}


      {(isDeleted) && (

        <div className="desktopWrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 512 512"
          >
            <path fill="#fff" d="M0 256C0 114.6 114.6 0 256 0s256 114.6 256 256-114.6 256-256 256S0 397.4 0 256zm96-48c-8.84 0-16 7.2-16 16v64c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16H96z"></path>
          </svg>
          <div>Not found.</div>
        </div>
      )}



      {(isDisabled) && (

        <div className="desktopWrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 512 512"
          >
            <path fill="#fff" d="M0 256C0 114.6 114.6 0 256 0s256 114.6 256 256-114.6 256-256 256S0 397.4 0 256zm96-48c-8.84 0-16 7.2-16 16v64c0 8.8 7.16 16 16 16h320c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16H96z"></path>
          </svg>
          <div>Not available.</div>
        </div>
      )}



      {debugInfo && <div onClick={(e) => getVDocForGUID(theGuid)} className="debugInfo">
       <span className="spinern"><svg
          className=""
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="20"
          heigh="20"
          style={{ display: "block", margin: "0px" }}
        >
          <path fill="#fff" d="M304 48C304 74.51 282.5 96 256 96C229.5 96 208 74.51 208 48C208 21.49 229.5 0 256 0C282.5 0 304 21.49 304 48zM304 464C304 490.5 282.5 512 256 512C229.5 512 208 490.5 208 464C208 437.5 229.5 416 256 416C282.5 416 304 437.5 304 464zM0 256C0 229.5 21.49 208 48 208C74.51 208 96 229.5 96 256C96 282.5 74.51 304 48 304C21.49 304 0 282.5 0 256zM512 256C512 282.5 490.5 304 464 304C437.5 304 416 282.5 416 256C416 229.5 437.5 208 464 208C490.5 208 512 229.5 512 256zM74.98 437C56.23 418.3 56.23 387.9 74.98 369.1C93.73 350.4 124.1 350.4 142.9 369.1C161.6 387.9 161.6 418.3 142.9 437C124.1 455.8 93.73 455.8 74.98 437V437zM142.9 142.9C124.1 161.6 93.73 161.6 74.98 142.9C56.24 124.1 56.24 93.73 74.98 74.98C93.73 56.23 124.1 56.23 142.9 74.98C161.6 93.73 161.6 124.1 142.9 142.9zM369.1 369.1C387.9 350.4 418.3 350.4 437 369.1C455.8 387.9 455.8 418.3 437 437C418.3 455.8 387.9 455.8 369.1 437C350.4 418.3 350.4 387.9 369.1 369.1V369.1z" />
        </svg></span>
      </div>}
    </>
  );
}

export default App;
